import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {Popup} from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import {toAbsoluteUrl} from '../../../../his/helpers'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .required('Please enter your email'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [reuse, setReuse] = useState(false)
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email.toLowerCase())
          .then((response) => {
            console.log(response)
            if(response.status){
              setHasErrors(false)
              if(typeof(response.type) != 'undefined' && response.type == 'reuse'){
                setHasErrors(true)
                setReuse(true)
              }
              setLoading(false)
            }else{
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setOpen(true)
            }
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setOpen(true)
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        style={{paddingBottom: '15%'}}
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your email address and we'll send you an email instructions to reset your password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {/* {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )} */}

        {hasErrors === false && (
          <div className='mb-10 bg-light-success p-8 rounded'>
            <div className='text-success'>Success! We've Sent You the Password Reset Link. Check Your Inbox for Next Steps.</div>
          </div>
        )}
        {reuse === true && (
          <div className='mb-10 bg-light-success p-8 rounded'>
            <div className='text-success'>The Password Reset Link already sent to your email before, please check your email.</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder='Email Address'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-success w-100 mb-2'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}

        {/* begin::back to login */}
        <div className='py-2 text-center'>
          Back to <Link to='/auth/login' className='link-primary fs-5'>Sign In</Link>
        </div>
        {/* end::back to login */}
      </form>
      <Popup open={open} closeOnDocumentClick onClose={closeModal} contentStyle={{padding: '25px'}}>
        <div style={{width: '100%', textAlign: 'center'}}>
        <img src={toAbsoluteUrl('/media/icons/alert.png')} alt="Error Alert" style={{width: '70px'}} />
          <h3 style={{paddingBottom: '15px',color: 'red'}}>Couldn’t Sent Email</h3>
          <p>HIS can't verify this account exists or maybe it's a google account / microsoft account.</p>
        </div>
        <div className='py-2 text-center'>
          <a className="link-primary fs-5" href="#" onClick={closeModal} style={{cursor: 'pointer'}}>
            Close
          </a>
        </div>
      </Popup>
    </>
  )
}
