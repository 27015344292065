import axios, {AxiosPromise, AxiosResponse} from 'axios'

// const API_URL = process.env.REACT_APP_API_URL
//microservice endpoint
const API_SERVICE_IAM = process.env.REACT_APP_API_SERVICE_IAM
const GET_LAUNCHER_URL = `${API_SERVICE_IAM}/v1/launcher`

const getLauncher = (): AxiosPromise => {
  return axios
    .get(`${GET_LAUNCHER_URL}`)
    .then((d: AxiosResponse) => d.data)
}

export {
  getLauncher,
}