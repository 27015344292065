import axios, { AxiosPromise, AxiosResponse } from 'axios'

// const API_URL = process.env.REACT_APP_API_URL
//microservice endpoint
const API_SERVICE_IAM = process.env.REACT_APP_API_SERVICE_IAM
const GET_MENU_URL = `${API_SERVICE_IAM}/v1/menu`
// const LAUNCHER_ID = process.env.REACT_APP_LAUNCHER_ID || 0

const getMenu = (LAUNCHER_ID: number = 0): AxiosPromise => {
  return axios
    .get(`${GET_MENU_URL}/${LAUNCHER_ID}`)
    .then((d: AxiosResponse) => d.data.filter((x: any) => x.launcher_id === LAUNCHER_ID || x.launcher_id === null))
    // .then((res: AxiosResponse) => res.data)
}

export {
  getMenu
}

