import axios, {AxiosPromise, AxiosResponse} from 'axios'
import {AnyObject} from 'chart.js/types/basic'
import {AuthModel, UserModel} from './_models'

// const API_URL = process.env.REACT_APP_API_URL
//microservice endpoint
const API_SERVICE_IAM = process.env.REACT_APP_API_SERVICE_IAM
const API_SERVICE_VENDORREGISTRATION = process.env.REACT_APP_API_SERVICE_VENDORREGISTRATION

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_SERVICE_IAM}/v1/verify-user`
export const GET_USER_VERIFY = `${API_SERVICE_VENDORREGISTRATION}/v1/verify-supplier`
export const LOGIN_URL = `${API_SERVICE_IAM}/v1/login`
export const VERIFICATION_URL = `${API_SERVICE_VENDORREGISTRATION}/v1/verification`
export const REGISTER_URL = `${API_SERVICE_IAM}/v1/register`
export const REQUEST_PASSWORD_URL = `${API_SERVICE_IAM}/v1/forgot_password`
export const VERIFY_TOKEN_FORGOT_URL = `${API_SERVICE_IAM}/v1/verify_token_forgot`
export const REQUEST_CHANGE_PASSWORD_URL = `${API_SERVICE_IAM}/v1/change_password`
export const EDIT_PROFILE_URL = `${API_SERVICE_IAM}/v1/edit_profile`
export const EDIT_PASSWORD_URL = `${API_SERVICE_IAM}/v1/edit_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  }).then((response: AxiosResponse) => response.data)
}

// Reset Password - Verify Token
export function verifyTokenForgot(token: string) {
  return axios.post<{result: boolean}>(VERIFY_TOKEN_FORGOT_URL, {
    token
  })
}

// Reset Password - Change Password
export function requestChangePassword(token: string, new_password: string, confirm_password: string) {
  return axios.post<{result: boolean}>(REQUEST_CHANGE_PASSWORD_URL, {
    token,
    new_password,
    confirm_password
  })
}

// Edit Profile
export function editProfile(name: string, username: string, avatar: string) {
  var bodyFormData = new FormData();
  bodyFormData.append('name', name);
  bodyFormData.append('username', username);
  bodyFormData.append('avatar', avatar);
  return axios({
    method: "post",
    url: EDIT_PROFILE_URL,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export function editPassword(old_password: string, new_password: string, confirm_password: string) {
  var bodyFormData = new FormData();
  bodyFormData.append('old_password', old_password);
  bodyFormData.append('new_password', new_password);
  bodyFormData.append('confirm_password', confirm_password);
  return axios({
    method: "post",
    url: EDIT_PASSWORD_URL,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

const getUserVerify = (token: AnyObject): AxiosPromise => {
  return axios.post(`${GET_USER_VERIFY}`, token).then((response: AxiosResponse) => response.data)
}

export function verifyUser(email: string, token: string, password: string) {
  return axios.post(VERIFICATION_URL, {
    email,
    token,
    password,
  })
}

export {getUserVerify}
