import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestChangePassword, verifyTokenForgot} from '../core/_requests'
import {Popup} from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import {useAuth} from '../core/Auth'
import {toAbsoluteUrl} from '../../../../his/helpers'
import PasswordWithChecklist from "./form/passwordWithChecklist"
import PasswordInput from "./form/passwordInput"

const initialValues = {
  token: '',
  new_password: '',
  confirm_password: ''
}

const changePasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .required('Please Enter your new password')
    .min(8, "Must be 8 characters or more")
    .matches(/[a-z]+/, "Must be One lowercase character")
    .matches(/.*[A-Z]/, "Must be One uppercase character")
    .matches(/.*\d/, "Must be One number")
    .matches(/.*[-/~!#*$@_%+=.,^&(){}[\]|;:<>?\\]/, "Must be One special character"),
  confirm_password: Yup.string()
    .required('Please Enter your confirmation password')
    .oneOf([Yup.ref('new_password'), null], 'Confirmation Passwords must match with New Password'),
})

export function ChangePassword() {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [cekToken, setCekToken] = useState(false)
  const closeModal = () => window.location.href = toAbsoluteUrl('/auth/login')
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const searchParams = new URLSearchParams(window.location.search)
  for (const value of searchParams.values()) {
    initialValues.token = value
  }

  if(!cekToken){
    verifyTokenForgot(initialValues.token)
      .then((response) => {
        if(response.status == 200){
          setOpen(false)
        }else{
          setOpen(true)
          setCekToken(true)
        }
      })
      .catch(() => {
        setOpen(true)
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestChangePassword(values.token, values.new_password, values.confirm_password)
          .then(() => {
            setHasErrors(undefined)
            setLoading(false)
            setSubmitting(false)
            setCekToken(true)
            setOpen(false)
            setOpenSuccess(true)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-3'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Reset Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Your new password must be different from previous used password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Success! We've Sent You the Password Reset Link. Check Your Inbox for Next Steps.</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          {/* <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
          <input
            placeholder='New Password'
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('new_password')}
            className={clsx(
              'form-control form-control-lg',
              {
                'is-invalid': formik.touched.new_password && formik.errors.new_password,
              },
              {
                'is-valid': formik.touched.new_password && !formik.errors.new_password,
              }
            )}
          />
          {formik.touched.new_password && formik.errors.new_password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.new_password}</span>
              </div>
            </div>
          )} */}
          <PasswordWithChecklist 
            autoComplete='off'
            {...formik.getFieldProps('new_password')} 
            name="new_password" 
            label="New Password" 
            placeholder="New Password"
            id="new_password" />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          {/* <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <input
            placeholder='Confirm Password'
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('confirm_password')}
            className={clsx(
              'form-control form-control-lg',
              {
                'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password,
              },
              {
                'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
              }
            )}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirm_password}</span>
              </div>
            </div>
          )} */}
          <PasswordInput autoComplete='off'
            {...formik.getFieldProps('confirm_password')} 
            name="confirm_password" 
            label="Confirm Password" 
            placeholder="Confirm Password"
            id="confirm_password" 
            />
          {formik.touched.confirm_password && formik.errors.confirm_password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirm_password}</span>
            </div>
          </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-success w-100 mb-2'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}

        {/* begin::back to login */}
        <div className='py-2 text-center'>
          Back to <Link to='/auth/login' className='link-primary fs-5'>Sign In</Link>
        </div>
        {/* end::back to login */}
      </form>
      <Popup open={open} closeOnDocumentClick onClose={closeModal} contentStyle={{padding: '25px'}}>
        <div style={{width: '100%', textAlign: 'center'}}>
        <img src={toAbsoluteUrl('/media/icons/alert.png')} alt="Error Alert" style={{width: '70px'}} />
          <h3 style={{paddingBottom: '15px',color: 'red'}}>Link Expired</h3>
          <p>Your link forgot password was expired, if you still forgot your password please request again.</p>
        </div>
        <div className='py-2 text-center'>
          <Link to='/auth/login' className='btn btn-lg btn-success mb-2'>Back to Sign In</Link>
        </div>
      </Popup>
      <Popup open={openSuccess} closeOnDocumentClick onClose={closeModal} contentStyle={{padding: '25px'}}>
        <div style={{width: '100%', textAlign: 'center'}}>
        <img src={toAbsoluteUrl('/media/icons/successgif.gif')} alt="Success" style={{width: '70px'}} />
          <h3 style={{paddingBottom: '15px',color: 'green'}}>Password Successfully Changed</h3>
          <p>Your password was successfully changed, please login again with your new password.</p>
        </div>
        <div className='py-2 text-center'>
          <Link to='/auth/login' className='btn btn-lg btn-success mb-2'>Back to Sign In</Link>
        </div>
      </Popup>
    </>
  )
}
