/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserVerify, verifyUser, getUserByToken, login} from '../core/_requests'
import {Link } from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../his/assets/ts/components'
import {useAuth} from '../core/Auth'

const initialValues = {
  email: '',
  password: '',
  token: ''
}
let isDisablePassword = true

const verificationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, "Must be 8 characters or more")
    .matches(/[a-z]+/, "Must be One lowercase character")
    .matches(/.*[A-Z]/, "Must be One uppercase character")
    .matches(/.*\d/, "Must be One number")
    .matches(/.*[-/~!#*$@_%+=.,^&(){}[\]|;:<>?\\]/, "Must be One special character").min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  token: Yup.string()
    .required('Token is required'),
})

export function Verification() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  // const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: verificationSchema, 
    validate: async (values) => {
      try {
        const data = await getUserVerify(values)
        if(data){
           isDisablePassword = false
        }else{
           isDisablePassword = true
        }
        // console.log(data)
      } catch (ex) {
        console.error(ex)
      }
    },
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const verifyData =  await verifyUser(values.email, values.token, values.password)
        
        if(verifyData){
          setLoading(true)
          await login(values.email.toLowerCase(), values.password).then(
            async (response) => {
              // Do something
              const {data: auth} = response
    
              saveAuth(auth)
              const {data: user} = await getUserByToken(auth.api_token)
              setCurrentUser(user)
            },
            (error) => {
              // example of setting error
              const errorData = JSON.parse(JSON.stringify(error))
              console.log(errorData)
              if(errorData.status === 500){
                saveAuth(undefined)
                setStatus(null)
                setSubmitting(false)
                setLoading(false)
              }else{
                saveAuth(undefined)
                if(errorData.status === 502){
                  setStatus("HIS can't verify this account exists or maybe it's a google account / microsoft account.")
                }else{     
                  setStatus('The login credential is incorrect.')
                }
                setSubmitting(false)
                setLoading(false)
              }
            })
          .finally(() => {
            setSubmitting(false)
            setLoading(false)
          })
          
          // const {data: auth} = await login(values.email, values.password)
          // saveAuth(auth)
          // const {data: user} = await getUserByToken(auth.api_token)
          // setCurrentUser(user)
        }else{
          
          setStatus('Verification Failed')
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        setStatus('Verification Failed')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_verification_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='card-body'>
      {/* begin::Heading */}
      <div className='text-start mb-10'>
        {/* begin::Title */}
          <h1 className='text-dark mb-3 fs-2x'>
            Vendor Verification
          </h1>
        {/* end::Title */}

      </div>
      {/* end::Heading */}


     

      {/* begin::Form group Email */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          style={{textTransform: 'lowercase'}}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Token */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Security Code</label>
        <input
          type='text'
          placeholder='Token'
          autoComplete='off'
          {...formik.getFieldProps('token')}
          className={clsx(
            'form-control form-control-lg',
            {
              'is-invalid': formik.touched.token && formik.errors.token,
            },
            {
              'is-valid': formik.touched.token && !formik.errors.token,
            }
          )}
        />
        {formik.touched.token && formik.errors.token && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.token}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-5 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Create New Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Create New Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg'
              )}
              
              disabled = { isDisablePassword }
              
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}


      {/* begin::Form group */}
      <div className='fv-row mb-10'>
            Already Verification ?
            <Link to='/auth/login' className='ms-1 link-primary'>
              Login
            </Link>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-md btn-success w-100'
          disabled={formik.isSubmitting || !formik.isValid }
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
      </div>
    </form>
  )
}
