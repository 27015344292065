import React, {useState, FormEvent } from "react";
import PasswordInput, { PasswordInputProps } from "./passwordInput";
import CheckList from "./checklist";

const PasswordWithChecklist = (props: PasswordInputProps) => {
  const { onChange, ...rest } = props;
  const [focused, setFocused] = useState(false)
  const [password, setPassword] = React.useState("");

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setPassword(target.value);
    if (onChange) onChange(e);
  };

  const handleFocus = () => {
    setFocused(true)
  };

  const handleBlur = () => {
    setFocused(false)
  };

  return (
    <div>
      <PasswordInput {...rest} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
      {focused === true && (
        <CheckList value={password} />
      )}
    </div>
  );
};

export default PasswordWithChecklist;
