import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../his/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../his/assets/ts/_utils'
import {WithChildren} from '../../his/helpers'
import { useSelectLauncher } from '../../his/helpers/crud-helper/hooks'

const PrivateRoutes = () => {
  const {pathname} = useLocation()
  const {setSelectedLauncher, setIsExpandableSubmenu} = useSelectLauncher()

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const ReceptionDatePage = lazy(() => import('../modules/apps/reception-date/Page'))
  const NonInventoryDashboard = lazy(() => import('../modules/apps/bi-dashboard/non-inventory-dashboard/Page'))
  const ARVehicleDashboard = lazy(() => import('../modules/apps/bi-dashboard/ar-vehicle-overdue/Page'))
  
  useEffect(() => {
    if (!pathname.includes('apps')) {
      setSelectedLauncher(0)
      setIsExpandableSubmenu(false)
    }
  }, [pathname])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route path='apps/vendor-registration/*' element={<Vendorregis />} />
        
        <Route
          path='apps/reception-date/*'
          element={
            <SuspensedView>
              <ReceptionDatePage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/general-affairs/bi-dashboard/*'
          element={
            <SuspensedView>
              <NonInventoryDashboard />
            </SuspensedView>
          }
        /> */}
        <Route
          path='apps/finance-accounting/bi-dashboard/*'
          element={
            <SuspensedView>
              <ARVehicleDashboard />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

function Vendorregis() {
  // 👇️ redirect to external URL
  window.location.replace('https://his-vendor-registration.hino.co.id/')

  return null
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
