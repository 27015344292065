/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
// import {toAbsoluteUrl} from '../../../../his/helpers'
import {useAuth} from '../core/Auth'
import {Link} from 'react-router-dom'
import {Popup} from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import {toAbsoluteUrl} from '../../../../his/helpers'
import PasswordInput from "./form/passwordInput"

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(32, 'Maximum 32 characters')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      await login(values.email.toLowerCase(), values.password).then(
        async (response) => {
          // Do something
          const {data: auth} = response

          saveAuth(auth)
          const {data: user} = await getUserByToken(auth.api_token)
          setCurrentUser(user)
        },
        (error) => {
          // example of setting error
          const errorData = JSON.parse(JSON.stringify(error))
          console.log(errorData)
          if(errorData.status === 500){
            saveAuth(undefined)
            setStatus(null)
            setOpen(true)
            setSubmitting(false)
            setLoading(false)
          }else{
            saveAuth(undefined)
            if(errorData.status === 502){
              setStatus("HIS can't verify this account exists or maybe it's a google account / microsoft account.")
            }else{     
              setStatus('The login credential is incorrect.')
            }
            setSubmitting(false)
            setLoading(false)
          }
        })
      .finally(() => {
        setSubmitting(false)
        setLoading(false)
      })
      
      // try {
      //   const {data: auth} = await login(values.email, values.password)

      //   saveAuth(auth)
      //   const {data: user} = await getUserByToken(auth.api_token)
      //   setCurrentUser(user)
      // } catch (error) {
      //   console.error(error)
      //   saveAuth(undefined)
      //   setStatus('The login detail is incorrect')
      //   setSubmitting(false)
      //   setLoading(false)
      // }
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_sign_in_form'
      >
        {/* begin::Body */}

        <div className='card-body'>
          {/* begin::Heading */}
          <div className='text-start mb-10'>
            <h1 className='text-dark mb-3 fs-2x' data-kt-translate='sign-in-title'>
              Hino Integrated System
            </h1>
            <div className='text-gray-400 fw-semibold fs-6' data-kt-translate='general-desc'>
              Please fill out the following form with your login credentials:
            </div>
          </div>
          {/* begin::Heading */}

          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div className=''></div>
          )}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email Address'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-2'>
            {/* <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              </div>
            </div>
            <input
              placeholder='Password'
              type='password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            <PasswordInput autoComplete='off'
              {...formik.getFieldProps('password')} 
              name="password" 
              label="Password" 
              placeholder='Password'
              id="password" 
              />
          </div>
          {/* end::Form group */}

          {/* begin::Forgot password link */}
          <div className='d-flex flex-stack py-2 text-right'>
            <Link to='/auth/forgot-password' className='link-primary fs-5'>
            Forgot Password ?
            </Link>
          </div>
          {/* end::forgot password */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-success w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Login</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </div>
        {/* end::Body */}
      </form>

      <Popup open={open} closeOnDocumentClick onClose={closeModal} contentStyle={{padding: '25px'}}>
        <div style={{width: '100%', textAlign: 'center'}}>
          <img src={toAbsoluteUrl('/media/icons/alert.png')} alt="Error Alert" style={{width: '70px'}} />
          <h3 style={{paddingBottom: '15px', color: 'red'}}>Sign-in attempt was blocked</h3>
          <p>You already try to sign-in three times. Try again later in 60 minutes</p>
        </div>
        <div className='py-2 text-center'>
          <Link to='/auth/forgot-password' className='btn btn-lg btn-success mb-2'>Forgot Password</Link>
          <div className='py-2 text-center'>
            Back to <a onClick={closeModal} style={{cursor: 'pointer'}} className='link-primary fs-5'>Sign In</a>
          </div>
        </div>
      </Popup>
    </>
  )
}
