import React from "react";
// import Collapse from "@material-ui/core/Collapse";
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

function SidebarItem({ depthStep = 10, depth = 0, expanded, item, ...rest }) {
  // const [collapsed] = React.useState(true);
  const { label, items, icon, link} = item;
  return (
    <>
    
    {
    items?.length > 0 ? (
      <AsideMenuItemWithSub
        to={link}
        title={label}
        fontIcon={icon}
        menuIcon='font'
      >
        {items?.map((subItem, index) => (
            <AsideMenuItem
                to={subItem.link}
                title={subItem.label}
                fontIcon={subItem.icon}
                menuIcon='font'
                key={index}
            />
        ))}
      </AsideMenuItemWithSub>
    ): 
      <AsideMenuItem
          to={link}
          title={label}
          fontIcon={icon}
          menuIcon='font'
      />
    }
      
    </>
  );
}

function Sidebar({ items, depthStep, depth, expanded }) {
  return (
    <>
        {items?.map((sidebarItem, index) => (
          <React.Fragment key={`${sidebarItem.label}${index}`}>
            {sidebarItem.type === "divider" ? (
                <div className='menu-item' key={index}>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{sidebarItem.label}</span>
                    </div>
                </div>
            ) : (
              <SidebarItem
                depthStep={depthStep}
                depth={depth}
                expanded={expanded}
                item={sidebarItem}
              />
            )}
          </React.Fragment>
        ))}
    </>
  );
}

export default Sidebar;