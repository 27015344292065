import {Dispatch, SetStateAction} from 'react'

export type ID = undefined | null | number

export type PaginationState = {
  page: number
  items_per_page: 10 | 30 | 50 | 100
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  items_per_page: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  itemIdForToken?: ID
  itemIdForApproval?: ID
  itemIdForDetail?: ID
  itemIdForRevise?: ID
  itemIdForCancel?: ID
  itemIdForReject?: ID
  itemIdForPrint?: ID
  setItemIdForToken: Dispatch<SetStateAction<ID>>
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  setItemIdForApproval: Dispatch<SetStateAction<ID>>
  setItemIdForDetail: Dispatch<SetStateAction<ID>>
  setItemIdForRevise: Dispatch<SetStateAction<ID>>
  setItemIdForCancel: Dispatch<SetStateAction<ID>>
  setItemIdForReject: Dispatch<SetStateAction<ID>>
  setItemIdForPrint: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export type FormViewContextProps = {
  itemIdForAddModal?: ID
  itemIdForBankModal?: ID
  itemIdForPicModal?: ID
  itemIdForPicModalView?: ID
  itemIdForIdentityModal?: ID
  itemIdForApprovalModal?: ID
  itemIdForReviseModal?: ID
  itemIdForCancelModal?: ID
  itemIdForRejectModal?: ID
  itemIdForPrivacyPolicyModal?: ID
  setItemIdForAddModal: Dispatch<SetStateAction<ID>>
  setItemIdForBankModal: Dispatch<SetStateAction<ID>>
  setItemIdForPicModal: Dispatch<SetStateAction<ID>>
  setItemIdForPicModalView: Dispatch<SetStateAction<ID>>
  setItemIdForIdentityModal: Dispatch<SetStateAction<ID>>
  setItemIdForApprovalModal: Dispatch<SetStateAction<ID>>
  setItemIdForReviseModal: Dispatch<SetStateAction<ID>>
  setItemIdForCancelModal: Dispatch<SetStateAction<ID>>
  setItemIdForRejectModal: Dispatch<SetStateAction<ID>>
  setItemIdForPrivacyPolicyModal: Dispatch<SetStateAction<ID>>
}



export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForToken: () => {},
  setItemIdForUpdate: () => {},
  setItemIdForApproval: () => {},
  setItemIdForDetail: () => {},
  setItemIdForRevise: () => {},
  setItemIdForCancel: () => {},
  setItemIdForReject: () => {},
  setItemIdForPrint: () => { },
  isAllSelected: false,
  disabled: false,
}

export const initialFormView: FormViewContextProps = {
  setItemIdForAddModal: () => {},
  setItemIdForBankModal: () => {},
  setItemIdForPicModal: () => {},
  setItemIdForPicModalView: () => {},
  setItemIdForIdentityModal: () => {},
  setItemIdForApprovalModal: () => {},
  setItemIdForReviseModal: () => {},
  setItemIdForCancelModal: () => { },
  setItemIdForRejectModal: () => {},
  setItemIdForPrivacyPolicyModal: () => {}
}

