//For development purpose

export const LauncherMap: {[key: string]: string} = {
  "http://34.101.141.65:3032/e_procurement": "http://localhost:3032/e_procurement"
}

export const remapLink = (link: string) => {
  //get absolute path of current path
  const currentPath = window.location.pathname;
  let currentDomain: String = currentPath.substring(0, currentPath.indexOf('/'));
  if(process.env.NODE_ENV === "development"){
    if(LauncherMap[link])
      link = LauncherMap[link];
  }
  const auth = localStorage.getItem('kt-auth-react-v');
  // to query string
  const queryString = auth ? `?auth=${auth}` : '';

  return link + queryString;
}