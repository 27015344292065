import { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export function useScript(url: string) {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.type = 'module'

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

interface IUseSelectLauncher {
  selectedLauncher: number
  setSelectedLauncher: (T: number) => void
  isExpandableSubmenu: boolean
  setIsExpandableSubmenu: (T: boolean) => void
}

export const useSelectLauncher = create<IUseSelectLauncher>()(
  persist(
    (set, get) => ({
      selectedLauncher: 0,
      setSelectedLauncher: (selectedLauncher) => set({ selectedLauncher }),
      isExpandableSubmenu: false,
      setIsExpandableSubmenu: (isExpandableSubmenu) => set({ isExpandableSubmenu }),
    }),
    {
      name: 'selected-launcher',
    },
  ),
)