/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelectLauncher} from '../../../his/helpers/crud-helper/hooks'
import {PageTitle} from '../../../his/layout/core'
import {remapLink} from '../../config/LauncherMap'
import LauncherCard from './components/LauncherCard'
import {Launcher} from './core/_model'
import {getLauncher} from './core/_request'

const BusinessUnitModal = ({
  businessUnit,
  setBusinessUnit,
}: {
  businessUnit: Launcher
  setBusinessUnit: any
}) => {
  const {setSelectedLauncher, setIsExpandableSubmenu} = useSelectLauncher()

  return (
    <>
      <div
        className='modal fade show'
        id='kt_modal_5'
        tabIndex={-1}
        aria-hidden='true'
        style={{display: 'block'}}
      >
        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='modal-title'>{businessUnit.admin_launcher.launcher_name}</h2>
              {/*close button*/}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                onClick={() => {
                  setBusinessUnit(null)
                }}
              >
                <span className='btn-icon'>
                  <i className={clsx('fa fa-close')}></i>
                </span>
              </div>
            </div>
            <div className='modal-body'>
              <div className='row'>
                {businessUnit.admin_launcher.items.map((item, index) => {
                  // item.id == 94 && console.log(item.link.includes(urlOrigin), item.link, urlOrigin);
                  // const goTo = '#' //item.link.includes(urlOrigin) ? item.link : remapLink(item.link)
                  const goTo = item.link.includes('?local=true')
                    ? item.link.replace('?local=true', '')
                    : remapLink(item.link)

                  return (
                    <div
                      className='col-xl-4 cursor-pointer p-2'
                      key={index}
                      onClick={() => {
                        item.launcher_id == 94 && setIsExpandableSubmenu(true)
                        setSelectedLauncher(item.launcher_id)
                      }}
                    >
                      <a href={goTo}>
                        <div className='card card-custom bg-secondary card-stretch '>
                          <div className='card-body d-flex flex-column'>
                            <div className='d-flex align-items-center justify-content-between mb-2 m-auto'>
                              {/*business unit icon*/}
                              <div className='symbol symbol-50 symbol-light-success mr-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={item.launcher_icon}
                                    className='h-50 align-self-center'
                                    alt=''
                                  />
                                </span>
                              </div>
                            </div>
                            <span className='text-dark-75 font-weight-bolder font-size-h5 text-center'>
                              {item.launcher_name}
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const BusinessDashboard = () => {
  const [launchers, setLaunchers] = useState<Launcher[]>([])
  const [businessUnit, setBusinessUnit] = useState<Launcher>()
  const getLauncherCall = async () => {
    const items: any = await getLauncher()
    setLaunchers(items)
  }

  useEffect(() => {
    getLauncherCall()
  }, [])

  return (
    <div>
      <div className='row flex justify-content-around'>
        {launchers.map((item, index) => {
          return (
            <LauncherCard
              key={index}
              onClick={() => {
                setBusinessUnit(item)
              }}
              item={item}
            />
          )
        })}
      </div>
      {/*  modal modules of chosen business unit*/}
      {businessUnit && (
        <BusinessUnitModal businessUnit={businessUnit} setBusinessUnit={setBusinessUnit} />
      )}
    </div>
  )
}

const DashboardPage: FC = () => {
  return (
    <>
      <BusinessDashboard />
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
