import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'

export function Blocked() {
  return (
    <>
      <div className='text-center mb-10'>
        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>You already try to sign-in three times. Try again later in 5 minutes.</div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className='text-center'>
        <Link to='/auth/fotgot-password' className='btn btn-lg btn-success w-100 mb-2'>
          Forgot Password
        </Link>
      </div>
      {/* end::Form group */}

      {/* begin::back to login */}
      <div className='py-2 text-center'>
        Back to <Link to='/auth/login' className='link-primary fs-5'>Sign In</Link>
      </div>
      {/* end::back to login */}

    </>
  )
}
