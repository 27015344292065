import {Launcher} from '../core/_model'
import React from 'react'
export default function LauncherCard(props: {onClick: () => void, item: Launcher}) {
  return <div className='col-xl-3 cursor-pointer mt-4' onClick={props.onClick}>
    <div className='card card-custom gutter-b card-stretch'>
      <div className='card-body d-flex flex-column'>
        <div className='d-flex align-items-center justify-content-between mb-2 m-auto'>
          {/*business unit icon*/}
          <div className='symbol symbol-50 symbol-light-success mr-2'>
            <span className='symbol-label'>
              <img
                src={props.item.admin_launcher.launcher_icon}
                className='h-50 align-self-center' alt='' />
            </span>
          </div>
        </div>
        <span className='text-dark-75 font-weight-bolder font-size-h5 text-center'>
          {props.item.admin_launcher.launcher_name}
        </span>
      </div>
    </div>
  </div>
}
