/* eslint-disable react/jsx-no-target-blank */
import {FC} from 'react'
// import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../helpers'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import Sidebar from "./Sidebar";

type Props = {
  items:any
}
const AsideMenuMain: FC<Props> = ({items}) => {
  return (
    <>
      <Sidebar items={items} depthStep={undefined} depth={undefined} expanded={undefined} />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
export {AsideMenuMain}
