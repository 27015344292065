/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Verification} from './components/Verification'
import {ForgotPassword} from './components/ForgotPassword'
import {ChangePassword} from './components/ChangePassword'
import {Blocked} from './components/Blocked'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../his/helpers'
import {Link} from 'react-router-dom'


const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-lg-row flex-column-fluid'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/background_login.jpg')})`,
      // }}
    >
      {/* begin::Logo */}
      <a href='#' className='d-block d-lg-none mx-auto'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/hino_logo.png')}
          className='theme-dark-show h-25px'
        />
      </a>
      {/* end::Logo */}
      {/* begin::Aside */}
      <div className='d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10'>
        {/* begin::Wrapper */}
        <div className='d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px'>
          {/* begin::Header */}
          <div className='d-flex flex-stack py-2 pb-10'>
            {/* begin::Back link */}
            <div className='me-2'></div>
            {/* end::Back link */}
            {/* begin::Sign Up link */}
            <div className='m-0'>
              <span
                className='text-gray-400 fw-bold fs-5 me-2'
                data-kt-translate='sign-in-head-desc'
              >
                Need Verification Vendor?
              </span>
              <Link to='/auth/verification' className='link-primary fs-5'>
                Here
              </Link>
            </div>
            {/* end::Sign Up link */}
          </div>
          {/* end::Header */}
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Body */}
      <div
        className='d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/background/bg11.jpg')})`,
        }}
      ></div>
      {/* end::Body */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='verification' element={<Verification />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='change-password' element={<ChangePassword />} />
      <Route path='blocked' element={<Blocked />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
