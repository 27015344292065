import React, { useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { AsideMenuMain } from './AsideMenuMain'
import { DrawerComponent, ScrollComponent, ToggleComponent } from '../../../assets/ts/components'
import { getMenu } from '../../core/_requests'
import { useSelectLauncher } from '../../../helpers/crud-helper/hooks'
// import { array } from 'yup/lib/locale'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({ asideMenuCSSClasses }) => {

  const scrollRef = useRef<HTMLDivElement | null>(null)
  const location = useLocation()
  const [menuData, setMenuData] = useState([])
  const { selectedLauncher, isExpandableSubmenu } = useSelectLauncher()
  async function menuCall() {
    const items: any = await getMenu(selectedLauncher)
    const itemChildrenTemp: any = []

    const unique = [...new Set(items.map((item: any) => item.label))]
    const menuDataTemp: any = unique.map((label: any) =>
      items.find((item: any) => item.label === label)
    )
    if (isExpandableSubmenu && items?.length > 0) {
      items?.forEach((item: any) => {
        if (item?.items[0]) {
          itemChildrenTemp.push(item?.items[0])
        }
      })

      menuDataTemp?.forEach(({ }, index: number) => {
        menuDataTemp[index].items = itemChildrenTemp
      })
    }
    if (location.pathname !== '/dashboard') {
      setMenuData(menuDataTemp)
    } else {
      const REACT_APP_WHITELIST_MENU = process.env.REACT_APP_WHITELIST_MENU || ''
      const whitelist_ids = REACT_APP_WHITELIST_MENU
        .replace(/\s+/g, '')
        .split(",")
        .map(id => Number(id))

      whitelist_ids.unshift(295)
      const filteredMenuData = menuDataTemp.filter((item: any) => whitelist_ids.includes(item.id))
      setMenuData(filteredMenuData)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      menuCall()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)

    // menuCall()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedLauncher])

  return (
    <div
      id='kt_aside_menu_wrapper'
      ref={scrollRef}
      className='hover-scroll-overlay-y my-5 my-lg-5'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside_menu'
      data-kt-scroll-offset='0'
    >
      <div
        id='#kt_aside_menu'
        data-kt-menu='true'
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        <AsideMenuMain items={menuData} />
      </div>
    </div>
  )
}

export { AsideMenu }
